import { Button } from 'antd'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import TicketContext from '../contexts/TicketContext'
import './Success.css'

function Success() {
    const navigate = useNavigate()
    const {changeRoomID, changeMode, changeActiveDevice, changeErrors, changeDescription} = useContext(TicketContext)

    const handleNavigate = () => {
        changeRoomID("")
        changeMode(null)
        changeActiveDevice(null)
        changeDescription("")
        changeErrors([])
        navigate('/')
    }
    return (
        <div className='Success'>
            <div className="wrapper"> <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
            </div>
            <div className="lowerContainer">
                <h2>Erfolgreich gesendet!</h2>
                <span>Hast du noch weitere Probleme entdeckt?</span>
            </div>
            <Button className='Button_send' style={{color: "white", width: "80%"}} onClick={() => handleNavigate()}>Neues Ticket erstellen</Button>
        </div>
    )
}

export default Success
