import React from 'react'

function QR({ size }) {
    return (

        <svg id="qr_code_scanner_black_24dp_2_" data-name="qr_code_scanner_black_24dp (2)" xmlns="http://www.w3.org/2000/svg" width={size} viewBox="0 0 24 24">
            <rect id="Rechteck_26" data-name="Rechteck 26" width="24" height="24" fill="none" />
            <path id="Pfad_21" data-name="Pfad 21" d="M9.5,6.5v3h-3v-3h3M11,5H5v6h6V5ZM9.5,14.5v3h-3v-3h3M11,13H5v6h6V13Zm6.5-6.5v3h-3v-3h3M19,5H13v6h6V5Zm-6,8h1.5v1.5H13Zm1.5,1.5H16V16H14.5ZM16,13h1.5v1.5H16Zm-3,3h1.5v1.5H13Zm1.5,1.5H16V19H14.5ZM16,16h1.5v1.5H16Zm1.5-1.5H19V16H17.5Zm0,3H19V19H17.5ZM21,7h0a1,1,0,0,1-1-1V4H18a1,1,0,0,1-1-1h0a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1V6A1,1,0,0,1,21,7Zm1,14V18a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v2H18a1,1,0,0,0-1,1h0a1,1,0,0,0,1,1h3A1,1,0,0,0,22,21ZM3,22H6a1,1,0,0,0,1-1H7a1,1,0,0,0-1-1H4V18a1,1,0,0,0-1-1H3a1,1,0,0,0-1,1v3A1,1,0,0,0,3,22ZM2,3V6A1,1,0,0,0,3,7H3A1,1,0,0,0,4,6V4H6A1,1,0,0,0,7,3H7A1,1,0,0,0,6,2H3A1,1,0,0,0,2,3Z" />
        </svg>

    )
}

export default QR
