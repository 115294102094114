import React, { useEffect, useContext } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Error from './pages/Error';
import Success from './pages/Success';
import 'antd/dist/antd.css';
import Naviagtion from './components/Navigation';
import GlobalState from './contexts/GlobalState';
import { Impressum } from './pages/Impressum';
import { Datenschutz } from './pages/Datenschutz';
import { Lizenz } from './pages/Lizenz';


function App() {



  useEffect(() => {

    //console.log("Im being rendered");
    /* Place for getting the device data. - useContext as a change variable */
  }, []);

  return (
    <GlobalState>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Naviagtion />} >
            <Route path=':identifier' element={<Naviagtion />} />
          </Route>
          <Route path='/error' element={<Error />} />
          <Route path='/success' element={<Success />} />
          <Route path='/impressum' element={<Impressum />} />
          <Route path='/datenschutzerklaerung' element={<Datenschutz />} />
          <Route path='/lizenz' element={<Lizenz />} />
        </Routes>
      </BrowserRouter>
    </GlobalState>

  );
}

export default App;
