import React, { useEffect, useContext } from 'react'
import { Html5Qrcode } from 'html5-qrcode';
import TicketContext from '../contexts/TicketContext';
import './Scanner.css'
import { Button } from 'antd';
import CloseIcon from '../graphics/CloseIcon';
import { pattern } from '../globals/globalVars';

const qrConfig = { fps: 10, qrbox: { width: 300, height: 300 } }


function Scanner() {

    const { changeRoomID, changeShowScanner, changeMode } = useContext(TicketContext)
    let html5QRCode;

    useEffect(() => {
        html5QRCode = new Html5Qrcode('reader');
        handleStart()
        return () => {
            handleStop()
        }
    }, [])

    const handleStart = () => {
        const scanSuccess = (decodedText, decodedResult) => {
            const idOfScan = decodedText.substring(decodedText?.length - 6, decodedText.length)
            if (idOfScan.match(pattern)) {
                changeMode("SCAN")
                changeRoomID(idOfScan)
            } else {
                changeRoomID("error")
            }
            window.navigator.vibrate(100)
            changeShowScanner(false)
            handleStop()
        };
        html5QRCode.start(
            { facingMode: "environment" },
            qrConfig,
            scanSuccess
        )
    }

    const handleStop = () => {
        try {
            html5QRCode
                .stop()
                .then((res) => {
                    html5QRCode.clear()
                })
                .catch((err) => {
                    //console.log(err.message);
                })
        } catch (error) {
            //console.log(error);
        }
    }


    return (
        <div className='Scanner-Container'>
            <div className="Scanner-Header">
                <h2 className="Scanner-Header_heading">QR-Code scannen</h2>
                <Button className='IconButton' icon={<CloseIcon size={24} />} onClick={() => changeShowScanner(false)} />
            </div>
            <div id='reader' width="100%" />
        </div>
    )


}

export default Scanner
