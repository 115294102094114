import { Button, Input, Dropdown, Menu } from 'antd'
import React, { useContext, useState, useRef } from 'react'
import TicketContext from '../contexts/TicketContext'
import { pattern } from '../globals/globalVars'
import QR from '../graphics/QR'
import './QR_CTA.css'

function QR_CTA() {
    const { changeShowScanner, changeRoomID, changeDevices, devices, changeMode, mode, roomID } = useContext(TicketContext)
    const [showInputField, setShowInputField] = useState(false)
    const [status, setStatus] = useState(null)
    const [value, setValue] = useState("")

    const menu = (
        <Menu >
          <Menu.Item key="1" onClick={() => {changeMode("MAN")}} >
            Manuelle Eingabe
          </Menu.Item>
          <Menu.Item key="2" onClick={() => {changeMode("QR")}} >
            Defekten QR-Code melden
          </Menu.Item>
          
        </Menu>
      );

    const validateInput = (value, mode) => {
        if (value.match(pattern) ) {
            changeRoomID(value)
            setStatus(true)  
        } else {
            setStatus(false)
        }
    }

    const checkForPattern = (target) => {
        if (target.value.match(pattern)) {
            target.blur()
        }
    }

    const inputRef = useRef(null)

    if (mode === null) {
        return (
            <div className='QR_CTA'>
                <QR size={48} />
                <Dropdown.Button bordered={"false"} className='QR_CTA-Button' overlay={menu} onClick={() => changeShowScanner(true)}>QR-Code scannen</Dropdown.Button >
                <div>
                </div>
            </div>
        )
    } else {
        return (
            <div className='QR_Manuel'>
                <span>Raumkennung<span className='required'>*</span></span>
                <Input autoFocus bordered={false} ref={inputRef} type='number' className='Input' onChange={(e) => {setValue(e.target.value); checkForPattern(e.target)}} placeholder='ID eingeben' value={value} onBlur={(e) => validateInput(e.target.value)}/>
                {inputRef.current?.value !== "" && status !== null && status !== true ? <span>Ungültige Eingabe</span> : null}
            </div>
        )
    }
}

export default QR_CTA
