import axios from 'axios'
import React, { useEffect, useReducer } from 'react'
import connectionInstance from '../api/instance'
import TicketContext from './TicketContext'
import ticketReducer from './TicketReducer'

const GlobalState = ({ children }) => {

    /* Get the Item from the localstorage | could be null */
    const data = JSON.parse(localStorage.getItem('ticketData'))
    
    /* Initial State */
    const initialState = {
        myTickets: data?.tickets ? data.tickets : [],
        ticketsOfUser: [],
        errrors: [],
        name: data && data.client.name !== "" ? data.client.name : "",
        date: new Date(),
        mail: data && data.client.mail !== "" ? data.client.mail : "",
        description: "",
        //* roomID is the identifier of the room
        roomID: "",
        roomData: null,
        showScanner: false,
        devices: [],
        activeDevice: null,
        activeTickets: [],
        mode: null,
        company: null,
        companyData: null,
    }

    /* Reducer to be able to change the state */
    const [state, dispatch] = useReducer(ticketReducer, initialState)

    
    
    //change the name
    const changeName = (new_Name) => {
        dispatch({
            type: 'CHANGE_NAME',
            payload: new_Name,
        })
    }
    
    //change the mail
    const changeMail = (new_Mail) => {
        dispatch({
            type: 'CHANGE_MAIL',
            payload: new_Mail,
        })
    }
    
    //change errors
    const changeErrors = (new_Errors) => {
        dispatch({
            type: 'CHANGE_ERRORS',
            payload: new_Errors,
        })
    }
    
    //change description
    const changeDescription = (new_Description) => {
        dispatch({
            type: 'CHANGE_DESCRIPTION',
            payload: new_Description,
        })
    }
    
    /* change the date */
    const changeDate = (new_Date) => {
        dispatch({
            type: 'CHANGE_DATE',
            payload: new_Date,
        })
    }
    
    /* change the ID of the room to get the devices of the room */
    const changeRoomID = (new_roomID) => {
        dispatch({
            type: 'CHANGE_roomID',
            payload: new_roomID,
        })
    }
    
    /* controls if the scanner is shown */
    const changeShowScanner = (new_Status) => {
        dispatch({
            type: 'CHANGE_SHOWSCANNER',
            payload: new_Status,
        })
    }

    /* change the activeDevice to determine the active Tickets of this device */
    const changeActiveDevice = (new_ActiveDevice) => {
        dispatch({
            type:'CHANGE_ACTIVEDEVICE',
            payload: new_ActiveDevice,
        })
    }

    const changeActiveTickets = (new_ActiveTickets) => {
        dispatch({
            type: 'CHANGE_ACTIVETICKETS',
            payload: new_ActiveTickets,
        })
    }

    /* change the devices matching the roomID */
    const changeDevices = (new_Devices) => {
        dispatch({
            type: 'CHANGE_DEVICES',
            payload: new_Devices,
        })
    }

    const changeRoomData = (new_RoomData) => {
        dispatch({
            type: 'CHANGE_ROOMDATA',
            payload: new_RoomData,
        })
    }

    const addTicket = (new_ticket) => {
        dispatch({
            type: 'ADD_TICKET',
            payload: new_ticket,
        })
    }

    //Ändert die companyID im State -> hat Einfluss auf das Logo und die Farbe der Buttons und Akzente
    const setCompany = (new_CompanyID) => {
        dispatch({
            type: 'SET_COMPANY',
            payload: new_CompanyID,
        })
    }

    /* Fetch active Tickets of activeDevice */
    const getActiveTicketsOfDevice = async () => {
        if (state.activeDevice !== null) {
            try {       
                const res = await connectionInstance.get('/activeTicketsOf/' + state.activeDevice)
                changeActiveTickets(res.data)
                
            } catch (e) {
                //console.log(e)
            }
        }
    }

    // change whether the qr-code should be select as default in the select
    const changeMode = (new_mode) => {
        dispatch({
            type: 'CHANGE_MODE',
            payload: new_mode,
        })
    } 

    /* Get the devices of the room and data for the room */
    const getDevicesOfRoom = async () => {
        if (state.roomData?._id) {
            try {
                const res = await connectionInstance.get('/devicesOfRoomID/' + state.roomData._id)
                changeDevices(res.data)
            } catch (e) {
                changeDevices([])
                //console.log(e)
            }
        }
    }

    //Holt sich die Raumdaten und setzt sie in den State für Raum - zusätzlich soll die ID des Unternehmens in den State der Company gesetzt werden.
    const getRoomData = async () => {
        if (state.roomID !== "" && state.roomID !== null && state.roomID !== "error") {
        try {
            const res = await connectionInstance.get('/roomData/' + state.roomID)
            if (!res.data) {
                changeRoomID("error")
            } else {
                setCompany(res.data.company)
                changeRoomData(res.data)
            }
        } catch (error) {
            changeRoomID("error")
            //console.log(error)
        }
    }
    }

    //Setzt die Unternehmensdaten in den State
    const changeCompanyData = (new_Data) => {
        dispatch({
            type: 'CHANGE_COMPANYDATA',
            payload: new_Data
        })
    }

    //Holt sich die Daten des Unternehmens im State, um Das Logo, die Farbe und andere Dinge verwenden zu können.
    const getCompanyData = async () => {
        if (state.company != null) {
            try {
                const res = await connectionInstance.get('/companyData/' + state.company)
                changeCompanyData(res.data)
            } catch (error) {
                console.error(error)
            }
        }
    }

    const getMyTickets = async () => {
        try {
            const res = await connectionInstance.post('/mytickets', {
                tickets: data.tickets
            })
            setTicketsOfUser(res.data)
        } catch (err) {
            //console.log(err)
        }
    }

    const setTicketsOfUser = (new_Tickets) => {
        dispatch({
            type: 'SET_TICKETSOFUSER',
            payload: new_Tickets
        })
    }

    const removeTicketOfUser = (ticketToRemove) => {
        dispatch({
            type: 'REMOVE_TICKETOFUSER',
            payload: ticketToRemove
        })
    }

    

    

    

    //TODO Logo should represent the company and by default it should be the base logo
    /* set devices according to the roomID and set the company */
    useEffect(() => {
        getRoomData()
    }, [state.roomID])
    
    useEffect(()=> {
        getDevicesOfRoom()
    }, [state.roomData])
    /* set the active Tickets of the active Device */
    useEffect(() => {
        getActiveTicketsOfDevice();
    }, [state.activeDevice])

    useEffect(() => {
        if (state.mode == "QR" && state.devices.length > 0) {
            changeActiveDevice(state.devices[0]._id)
        }
    }, [state.devices])

    useEffect(() => {
        getCompanyData()
    }, [state.company])

    useEffect(() => {
        getMyTickets()
    }, [state.myTickets])

    useEffect(() => {
        if (state.companyData?.color) {
            document.querySelector(':root').style.setProperty('--companyColor', state.companyData.color);
        }
    }, [state.companyData?.color])



    //TODO: reduce amount of variables in the context
    return (
        <TicketContext.Provider value={{
            errors: state.errors,
            myTickets: state.myTickets,
            ticketsOfUser: state.ticketsOfUser,
            name: state.name,
            mail: state.mail,
            mode: state.mode,
            company: state.company,
            companyData: state.companyData,
            fallbackColor: state.fallbackColor,
            devices: state.devices,
            activeDevice: state.activeDevice,
            description: state.description,
            roomID: state.roomID,
            roomData: state.roomData,
            showScanner: state.showScanner,
            date: state.date,
            activeTickets: state.activeTickets,
            changeDate,
            changeRoomData,
            changeDescription,
            changeMail,
            changeName,
            changeErrors,
            changeRoomID,
            changeShowScanner,
            changeDevices,
            changeActiveDevice,
            changeMode,
            addTicket,
            removeTicketOfUser
        }}>
            {children}
        </TicketContext.Provider>
    )
}


export default GlobalState
