import React, { useEffect, useContext, useState } from 'react'
import TicketContext from '../contexts/TicketContext'
import MyTicketItem from './MyTicketItem'
import connectionInstance from '../api/instance'

const MyTicketsContainer = () => {

    const { myTickets, ticketsOfUser, removeTicketOfUser} = useContext(TicketContext)


    

    if (ticketsOfUser.length > 0) {
        /* if the user has active tickets‚ */
        return (
            <div style={{display: "flex", flexDirection: "column", gap: "5px"}}>
                {ticketsOfUser.map(item => <MyTicketItem data={item} removeTicketOfUser={(x) => removeTicketOfUser(x)} />)}
            </div>
        )
    } else {
        /* if the user has no active Tickets in the localstorage */
        return (
            <div>
                Du hast auf diesem Gerät noch keine Tickets aufgegeben.
            </div>
        )
    }
}

export default MyTicketsContainer
