import { Divider } from 'antd'
import React from 'react'
import { Bar } from '../components/Bar'

export const Impressum = () => {
  return (
    <div style={{width: "100vw", height: "100vh"}}>
        <Bar />
        <div style={{padding: "10px"}}>
            <h2 style={{paddingBottom: "10px"}} >Impressum</h2>
            <div className='flex column'>
                <span style={{marginBottom: "10px", fontWeight: "600"}}>Offenlegung gemäß Mediengesetz und Angaben nach ECG</span>
                <span>Fabian Lukas Becker</span>
                <span>Lannerweg 113</span>
                <span>Krumpendorf am Wörthersee</span>
                <span>Österreich</span>
                <Divider />
                <span >Unternehmensgegenstand: <span style={{marginLeft: "5px"}}>Ankündigungsunternehmen</span></span>
                <span>Mitglied der WKO</span>
                <span>Berufsrecht: <a href='https://www.ris.bka.gv.at' rel="nofollow noopener" target="_blank"> www.ris.bka.gv.at</a></span>
                <span>Aufsichtsbehörde/Gewerbebehörde: Bezirkshauptmannschaft Klagenfurt-Land</span>
                <span>Verleihungsstaat: Österreich</span>
                <Divider />
            </div>
            <p>
            Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO (EU Nr. 524/2013): Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Du hier findest: <br/>
            <a href="http://ec.europa.eu/consumers/odr/" rel="nofollow noopener" target="_blank">http://ec.europa.eu/consumers/odr/</a>
            </p>

        </div>

    </div>
  )
}
