import { Divider } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Bar } from '../components/Bar'

export const Datenschutz = () => {
    const [content, setContent] = useState("<div>Laden...</div>")
    const [error, setError] = useState(false)

    const fetchData = async () => {
       try{
            const res =  await axios.get('https://www.iubenda.com/api/privacy-policy/65218800/only-legal')
            setContent(res.data.content)
            setError(false)
        } catch (error) {
            setError(true)
        }
    }

    useEffect(()=> {
        fetchData()
    }, [])
  return (
    <div style={{width: "100vw", height: "100vh"}}>
        <Bar />
        <div style={{padding: "10px"}} dangerouslySetInnerHTML={{__html: content}}>
            {error ? <div>Ein Fehler ist aufgetreten. Bitte lade die Seite neu, um den Inhalt zu sehen.</div> : null}
        </div>

    </div>
  )
}
