import React from 'react'

export const ArrowIcon = ({size}) => {
  return (
    <svg id="arrow_back_black_24dp" xmlns="http://www.w3.org/2000/svg" width={size} viewBox="0 0 24 24">
    <path id="Pfad_62" data-name="Pfad 62" d="M0,0H24V24H0Z" fill="none"/>
    <path id="Pfad_63" data-name="Pfad 63" d="M19,11H7.83l4.88-4.88a1.008,1.008,0,0,0,0-1.42,1,1,0,0,0-1.41,0L4.71,11.29a1,1,0,0,0,0,1.41l6.59,6.59a1,1,0,0,0,1.41-1.41L7.83,13H19a1,1,0,0,0,0-2Z"/>
    </svg>

  )
}
