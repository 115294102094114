const HashTagIcon = ({size}) => {
    return (
      <svg id="tag_black_24dp" xmlns="http://www.w3.org/2000/svg" width={size} viewBox="0 0 24 24">
        <g id="Gruppe_55" data-name="Gruppe 55">
          <rect id="Rechteck_76" data-name="Rechteck 76" width={size} fill="none"/>
        </g>
        <g id="Gruppe_56" data-name="Gruppe 56">
          <path id="Pfad_45" data-name="Pfad 45" d="M20,9h0a1,1,0,0,0-1-1H16V5a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1V8H10V5A1,1,0,0,0,9,4H9A1,1,0,0,0,8,5V8H5A1,1,0,0,0,4,9H4a1,1,0,0,0,1,1H8v4H5a1,1,0,0,0-1,1H4a1,1,0,0,0,1,1H8v3a1,1,0,0,0,1,1H9a1,1,0,0,0,1-1V16h4v3a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V16h3a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H16V10h3A1,1,0,0,0,20,9Zm-6,5H10V10h4Z"/>
        </g>
      </svg>
    )
  }

  export default HashTagIcon