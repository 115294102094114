import React from 'react'
import './StatusIcon.css'

function StatusIcon({ icon, size }) {
    if (icon == 'progress') {
        return (
            <svg id="hourglass_bottom_black_24dp_1_" data-name="hourglass_bottom_black_24dp (1)" xmlns="http://www.w3.org/2000/svg" width={size} viewBox="0 0 24 24" className='PendingIcon'>
                <g id="Gruppe_19" data-name="Gruppe 19">
                    <rect id="Rechteck_27" data-name="Rechteck 27" width="24" height="24" fill="none" />
                    <rect id="Rechteck_28" data-name="Rechteck 28" width="24" height="24" fill="none" />
                </g>
                <g id="Gruppe_20" data-name="Gruppe 20">
                    <path id="Pfad_22" data-name="Pfad 22" d="M16,22a2.006,2.006,0,0,0,2-2l-.01-3.18a2.016,2.016,0,0,0-.58-1.41L14,12l3.41-3.43a2,2,0,0,0,.58-1.41L18,4a2.006,2.006,0,0,0-2-2H8A2.006,2.006,0,0,0,6,4V7.16a2.039,2.039,0,0,0,.58,1.42L10,12,6.59,15.4A2.006,2.006,0,0,0,6,16.82V20a2.006,2.006,0,0,0,2,2ZM8,7.09V5A1,1,0,0,1,9,4h6a1,1,0,0,1,1,1V7.09a1.033,1.033,0,0,1-.29.71L12,11.5,8.29,7.79A1.009,1.009,0,0,1,8,7.09Z" />
                </g>
            </svg>

        )
    } else if (icon == 'IsProcessed') {
        return (
            <svg id="settings_black_24dp_1_" data-name="settings_black_24dp (1)" xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 24 24" className='ProcessingIcon'>
                <rect id="Rechteck_29" data-name="Rechteck 29" width="24" height="24" fill="none" />
                <path id="Pfad_23" data-name="Pfad 23" d="M19.5,12c0-.23-.01-.45-.03-.68l1.86-1.41a1.006,1.006,0,0,0,.26-1.3L19.72,5.38a.987.987,0,0,0-1.25-.42l-2.15.91a7.587,7.587,0,0,0-1.17-.68l-.29-2.31A1,1,0,0,0,13.87,2H10.14a1,1,0,0,0-1,.88L8.85,5.19a7.587,7.587,0,0,0-1.17.68L5.53,4.96a.987.987,0,0,0-1.25.42L2.41,8.62a1.008,1.008,0,0,0,.26,1.3l1.86,1.41c-.02.22-.03.44-.03.67s.01.45.03.68L2.67,14.09a1.006,1.006,0,0,0-.26,1.3l1.87,3.23a.987.987,0,0,0,1.25.42l2.15-.91a7.587,7.587,0,0,0,1.17.68l.29,2.31a1,1,0,0,0,.99.88h3.73a1,1,0,0,0,.99-.88l.29-2.31a7.587,7.587,0,0,0,1.17-.68l2.15.91a.987.987,0,0,0,1.25-.42l1.87-3.23a1.008,1.008,0,0,0-.26-1.3l-1.86-1.41A5.17,5.17,0,0,0,19.5,12Zm-7.46,3.5a3.5,3.5,0,1,1,3.5-3.5A3.5,3.5,0,0,1,12.04,15.5Z" />
            </svg>

        )
    } else {
        return null
    }
    }

export default StatusIcon
