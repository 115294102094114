import { Collapse } from 'antd'
import React from 'react'
import HashTagIcon from '../graphics/HashTagIcon'
import StatusIcon from '../graphics/StatusIcon'
import './ActiveTicketItem.css'

function ActiveTicketItem({ data }) {
    const {Panel} = Collapse
    const dateToProgress = (new Date().getTime() - data.issuedAt)/1000/60/60/24
    const date = dateToProgress > 0.5 ? Math.ceil(dateToProgress) : Math.floor(dateToProgress)
    
    //TODO New format for the active Ticket -> more Information about the ticket to give user useful information
    //* Label: TicketId -> Unique Identifier of the database: ObjectId or different ID
    return (
        <Collapse ghost bordered={false} expandIconPosition='right' key={data._id}>
            <Panel extra={<StatusIcon size={16} icon={data.status == 0 ? 'IsProcessed' : 'progress'}/>} header={data?.nr ? <div style={{display: "flex", alignItems: "center", gap: "2px"}}><HashTagIcon size={18} /> <span style={{fontWeight: "500"}}>{data.nr}</span></div> : <div style={{display: "flex", alignItems: "center", gap: "2px"}}><HashTagIcon size={18} /> <span style={{fontWeight: "500"}}>{"xxx"}</span></div>}>
                <div className='flex column' style={{gap: "8px"}}>
                    <div className='flex column'>
                        <span className="label">Status:</span>
                        <div style={{backgroundColor: data.status == 0 ? "var(--recieved)" : "var(--progress)", width: "fit-content", padding: "1px 10px", borderRadius: "10px", border: data.status == 0 ? "1px solid var(--recieved_border)" : "1px solid var(--progress_border)"}}>
                            <span>{data.status == 0 ? "Empfangen" : "In Bearbeitung"}</span>
                        </div>
                    </div>
                    <div className='flex column'>
                        <span className="label">Aufgegeben:</span>
                        {date == 0 ? "Heute" : (date == 1 ? "Gestern" : "Vor " + date + " Tagen.")}
                    </div>
                    <div className='flex column'>
                        <span className="label">Beschreibung:</span>
                        <p>{data.description}</p>
                    </div>
                    <div className='flex column'>
                        <span className="label">Fehlermeldungen:</span>
                        <div className='flex column' style={{gap: "5px"}}>
                            {data.deviceErrors.map(item => (
                                <div className='error'>{item}</div>
                            ))}
                        </div>
                    </div>
                </div>
            </Panel>
        </Collapse>
    )
}

export default ActiveTicketItem
