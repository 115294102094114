import { Button, Divider } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import ActiveTickets from '../components/ActiveTickets'
import DeviceData from '../components/DeviceData'
import PersonaForm from '../components/PersonaForm'
import TicketContext from '../contexts/TicketContext'
import './Home.css'
import connectionInstance from '../api/instance'
import { useNavigate, NavLink } from 'react-router-dom'

const Home = () => {
    const {  roomData, date, name, mail, errors, description, activeDevice, addTicket, changeRoomID, changeMode, changeActiveDevice } = useContext(TicketContext)
    const checkConditions = (parameter) => {
        if (parameter === null || parameter === undefined || parameter?.length === 0) return true
        return false
    }
    const validateCondition = () => {
        if (checkConditions(date) || checkConditions(name) || checkConditions(errors) || checkConditions(description) || checkConditions(activeDevice)) return true
        return false
    }
    let dataPackage = {
        description: description,
        deviceErrors: errors
    }

    const [error, setError] = useState(false)

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const handleSend = async () => {
        const entry = {
            ...dataPackage,
            issuedAt: new Date().getTime(),
            priority: 1,
            company: roomData?.company,
            house: roomData?.house,
            room: roomData?._id,
            client: {
                name: name,
                mail: mail
            },
            status: 0,
            device: activeDevice,
            errorDate: date,
        }
        try {
            setIsLoading(true)
            let userItems = JSON.parse(localStorage.getItem('ticketData'))
            if (userItems == null || userItems == undefined) {
                userItems = []
            } else {
                userItems = userItems.tickets
            }
            const ticket = await connectionInstance.post('/tickets', {
                ...entry
            })
            localStorage.setItem('ticketData', JSON.stringify({ client: { name: entry.client.name, mail: entry.client.mail }, tickets: [...userItems, ticket.data._id] }))
            addTicket(ticket.data._id)
            setError(false)
            navigate('/success')
        } catch (err) {
            //console.log(err);
            setError(true)
        } finally {
            setIsLoading(false)
        }
    }



    return (
        <div className='Home'>
            <DeviceData />
            <Divider />
            <PersonaForm />
            <div className="Home_send">
                <Button loading={isLoading} type='primary' disabled={validateCondition()} onClick={() => handleSend()} className='Button_send'>{error ? "Erneut versuchen":"Abschicken"}</Button>
                <div style={{color: "var(--contrast)", display: "grid", placeItems: "center", marginTop: "25px", fontSize: "12px"}}>Mit dem Klick auf "Abschicken" stimmst du der <div style={{color: "inherit"}}><NavLink style={{color: "var(--blue)"}} to='/datenschutzerklaerung'>Datenschutzerklärung</NavLink> zu.</div></div>
            </div>
            <Divider style={{borderTop: "1px solid var(--borderColor) !important"}}/>
            <ActiveTickets />
            <div className="Link-Container" style={{margin: "20px 0"}}>
                <NavLink className='Link-Container-Link' to='/impressum'>Impressum</NavLink>
                <span className='dot-divider'>•</span>
                <NavLink className='Link-Container-Link' to='/datenschutzerklaerung'>Datenschutzerklärung</NavLink>
                <span className='dot-divider'>•</span>
                <NavLink className='Link-Container-Link' to='/lizenz'>Softwarelizenz</NavLink>

            </div>

        </div>
    )
}

export default Home
