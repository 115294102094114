import { Input, Select } from 'antd'
import React, { useState, useContext } from 'react'
import './PersonaForm.css'
import TicketContext from '../contexts/TicketContext'
import { DatePicker } from 'antd'
import moment from 'moment'

const { Option } = Select
function PersonaForm() {
    const dateFormat = "YYYY-MM-DD"
    const { name, date, changeName, mail, changeMail, errors, changeErrors, description, changeDescription, changeDate } = useContext(TicketContext)

    document.getElementById('removeClassName')?.classList.remove('ant-picker-borderless')

    return (
        <div className='PersonaForm'>
            <div className="PersonaForm_row">
                <span className='label'>Datum<span className='required'>*</span></span>
                <DatePicker allowClear={false} bordered={false} defaultValue={date === "" || date === undefined ? moment(moment(), dateFormat) : moment(date, dateFormat)} format="DD.MM.YYYY" placeholder='Datum auswählen' className='Input' onChange={(e) => changeDate(e?._d)} />
            </div>
            <div className="PersonaForm_row">
                <span className='label'>Name<span className='required'>*</span></span>
                <Input bordered={false} className='Input' placeholder='Name eingeben' defaultValue={name} onChange={(e) => changeName(e.target.value.trim())} />
            </div>
            <div className="PersonaForm_row">
                <span className="label">E-Mail für Antwort</span>
                <Input bordered={false} type='email' placeholder='E-Mail eingeben' className='Input' defaultValue={mail} onChange={(e) => changeMail(e.target.value.trim())} />
            </div>
            <div className="PersonaForm_row">
                <span className="label">Fehler auswählen oder eingeben<span className='required'>*</span></span>
                <Select
                    bordered={false}
                    mode='tags'
                    placeholder='Fehler auswählen oder eingeben'
                    className='Error_Input'
                    defaultValue={errors}
                    onChange={(value) => changeErrors(value)}
                >
                    <Option key='Defekt'>Defekt</Option>
                    <Option key='Fehlermeldung'>Fehlermeldung</Option>
                </Select>
            </div>
            <div className="PersonaForm_row">
                <span className="label">Beschreibung<span className='required'>*</span></span>
                <Input bordered={false} className='Input' placeholder='Beschreibung eingeben' defaultValue={description} onChange={(e) => changeDescription(e.target.value.trim())} />
            </div>
        </div>
    )
}

export default PersonaForm
