import { Button } from 'antd'
import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { ArrowIcon } from '../graphics/ArrowIcon'

export const Bar = () => {
    const navigate = useNavigate()
  return (
    <div style={{position: "relative", order: "-1", top: "0", display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%", padding: "10px", left: "0"}}>
        <NavLink to='/'><Button className='IconButton' ghost icon={<ArrowIcon size={24} />} /></NavLink>
    </div>
  )
}
