import React, { useEffect, useContext, useState } from 'react'
import MyTicketItem from '../components/MyTicketItem'
import TicketTable from '../components/TicketTable'
import './MyTickets.css'
import connectionInstance from '../api/instance'
import TicketContext from '../contexts/TicketContext'
import MyTicketsContainer from '../components/MyTicketsContainer'

function MyTickets() {





    return (
        <div className='MyTickets'>
            <MyTicketsContainer />
        </div>
    )
}

export default MyTickets
