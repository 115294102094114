import { Collapse, Divider, Steps } from 'antd'
import React from 'react'
import ErrorIcon from '../graphics/ErrorIcon'
import HashTagIcon from '../graphics/HashTagIcon'
import './MyTicketItem.css'

const { Panel } = Collapse
const { Step } = Steps
function MyTicketItem({ data, removeTicketOfUser }) {
    const dateToProgress = (new Date().getTime() - data.issuedAt)/1000/60/60/24
    const date = dateToProgress > 0.5 ? Math.ceil(dateToProgress) : Math.floor(dateToProgress)

    const handleRemove = () => {
        const ticketData = JSON.parse(localStorage.getItem('ticketData'))
        const filtered = ticketData.tickets.filter(x => x !==  data._id)
        removeTicketOfUser(data._id)
        localStorage.setItem('ticketData', JSON.stringify({client: ticketData.client, tickets: filtered}))
    }

    
    return (
        <Collapse ghost expandIconPosition='right' bordered={false} key={data._id}>
            <Panel header={data?.nr ? <div style={{display: "flex", alignItems: "center", gap: "2px"}}><HashTagIcon size={18} /> <span style={{fontWeight: "500"}}>{data.nr}</span></div> : <div style={{display: "flex", alignItems: "center", gap: "2px"}}><HashTagIcon size={18} /> <span style={{fontWeight: "500"}}>{"xxx"}</span></div>} key={data._id}>
                <div className='flex column'>
                    <span className="label">Aufgegeben:</span>
                    <span>{date == 0 ? "Heute" : (date == 1 ? "Gestern" : "Vor " + date + " Tagen.")}</span>
                </div>
                <div className='flex column'>
                    <span className="label">Raum:</span>
                    <span>{data.roomName}</span>
                </div>
                <div className='flex column'>
                    <span className="label">Gerät:</span>
                    <span>{data.deviceName}</span>
                </div>
                <div className='flex column'>
                    <span className="label">Beschreibung:</span>
                    <span>{data.description}</span>
                </div>
                <Divider />
                <Steps direction='vertical' size='small' current={data.status}>
                    <Step title='Empfangen' description='Dein Ticket is angekommen und wird demnächst bearbeitet.' />
                    <Step title='In Bearbeitung' description='Dein Ticket wird gerade bearbeitet.' />
                    <Step title='Abschluss' description='Dein Ticket wurde erfolgreich bearbeitet.' />
                </Steps>
                {data.status == 3 ? <div className='flex column'>
                    <span className="label">Nachricht:</span>
                    <p>{data.message ? data.message : "Keine Nachricht wurde hinterlassen."}</p>
                </div> : null}
                <Divider />
                <div>
                    <button style={{backgroundColor: "var(--primary_tertiary)", outline: "none", border: "none", padding: "5px 10px", borderRadius: "10px"}} onClick={() => handleRemove()}>Ticket entfernen</button>
                </div>
            </Panel>
        </Collapse>
    )
}

export default MyTicketItem