import React from 'react'

function Error() {
    return (
        <div>
            Fatal error. RIP
        </div>
    )
}

export default Error
