import { Button, Menu, Dropdown } from 'antd'
import React, { useContext } from 'react'
import TicketContext from '../contexts/TicketContext';
import ErrorIcon from '../graphics/ErrorIcon'
import './ErrorContainer.css'

function ErrorContainer() {

    const { changeShowScanner, changeMode , changeRoomID} = useContext(TicketContext);
    const menu = (
        <Menu >
          <Menu.Item key="1" onClick={() => {changeMode("MAN"); changeRoomID("")}} >
            Manuelle Eingabe
          </Menu.Item>
          <Menu.Item key="2" onClick={() => {changeMode("QR"); changeRoomID("")}} >
            Defekten QR-Code melden
          </Menu.Item>
          
        </Menu>
      );
    return (
        <div className='ErrorContainer'>
            <div className="Error_message">
                <ErrorIcon size={24} />
                <span>Ungültige Kennung</span>
            </div>
            <Dropdown.Button className='QR_CTA-Button' overlay={menu} onClick={() => changeShowScanner(true)}>Erneut versuchen</Dropdown.Button>
        </div>
    )
}

export default ErrorContainer
