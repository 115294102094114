import React, { useState, useEffect, useContext } from 'react'
import './Navigation.css'
import Logo from '../graphics/Logo'
import Home from '../pages/Home';
import MyTickets from '../pages/MyTickets';
import { Badge, Button } from 'antd';
import QR from '../graphics/QR';
import TicketContext from '../contexts/TicketContext';
import { useParams } from 'react-router-dom';
import { pattern } from '../globals/globalVars';
import Scanner from '../components/Scanner'
import { CompanyLogo } from '../graphics/CompanyLogo';


function Naviagtion() {
    /* Handles the active page from the tabs */
    const [activePage, setActivePage] = useState('Home')

    /* Gets the parameters from the URL */
    const params = useParams()

    /* gets the global state variables and functions to change them */
    const { roomID, changeRoomID, changeShowScanner, companyData, fallbackColor, ticketsOfUser, showScanner } = useContext(TicketContext)



    /* Sets the global room ID */
    const setroomID = () => {
        if (params.identifier == null) return

        /* Match the pattern of the identifier */
        if (params.identifier.match(pattern)) {
            changeRoomID(params.identifier)
        } else {
            changeRoomID("error")
        }
    }

    useEffect(() => {
        setroomID()

    }, []);


    return (
        <div className='Navigation'>
            <div className='Navbar'>

                <div className='Navbar_upper'>
                    {companyData?.logo ? <img src={`data:image/svg+xml;utf8,${encodeURIComponent(companyData.logo)}`} height="20"/> : <CompanyLogo height={20} />}
                    {
                        roomID !== "" && roomID !== "error" ? <Button className='IconButton' icon={<QR size={20} />} onClick={() => changeShowScanner(true)} /> : null
                    }

                </div>
                {/* Tabs */}
                <div className='TabContainer'>
                    <div className={activePage === 'Home' ? 'TabPanel ' : 'TabPanel'} onClick={() => setActivePage('Home')}>
                        <span>Ticket erstellen</span>
                        <span style={activePage === 'Home' ? {backgroundColor: companyData ? companyData?.color : fallbackColor } : null} className={activePage === 'Home' ? 'indicator active' : 'indicator'}></span>
                    </div>
                    <div className={activePage === 'Home' ? 'TabPanel' : 'TabPanel '} onClick={() => setActivePage('MyTickets')}>
                            <div style={{display: "flex", alignItems: "center"}}><span>Meine Tickets</span><div className='badge'>{ticketsOfUser.length}</div></div>
                        <span style={activePage === 'Home' ? null: {backgroundColor: companyData ? companyData?.color : fallbackColor }} className={activePage === 'Home' ? 'indicator' : 'indicator active'}></span>
                    </div>
                </div>
            </div>

            <div className='page-container'>
                {activePage === 'Home' ? <Home status={false} /> : <MyTickets />}
            </div>
            <div>
            </div>
            {showScanner ? <Scanner /> : null}
        </div>
    )
}

export default Naviagtion
