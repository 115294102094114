import React from 'react';

function RoomIcon({size}) {
  return (
    <svg id="door_front_black_24dp_1_"  width={size} viewBox="0 0 24 24">
    <g id="Gruppe_23" data-name="Gruppe 23">
      <path id="Pfad_28" data-name="Pfad 28" d="M0,0H24V24H0Z" fill="none"/>
    </g>
    <g id="Gruppe_24" data-name="Gruppe 24">
      <path id="Pfad_29" data-name="Pfad 29" d="M19,19V5a2.006,2.006,0,0,0-2-2H7A2.006,2.006,0,0,0,5,5V19H3v2H21V19Zm-2,0H7V5H17Zm-4-8h2v2H13Z"/>
    </g>
  </svg>
  )
}

export default RoomIcon;
