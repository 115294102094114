import React from 'react'

export const CompanyLogo = ({height}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  height={height} viewBox="0 0 60.915 14.066">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rechteck_77" data-name="Rechteck 77" width="60.915" height="14.066" transform="translate(0 0)" fill="none"/>
      </clipPath>
    </defs>
    <g id="Gruppe_58" data-name="Gruppe 58" transform="translate(0 0)">
      <g id="Gruppe_57" data-name="Gruppe 57" clip-path="url(#clip-path)">
        <path id="Pfad_52" data-name="Pfad 52" d="M.828,6.182H0V4.227H.828V2.6H3.191V4.227H4.414V6.182H3.191V8.437c0,.36.156.516.552.516h.683v2H3.407A2.265,2.265,0,0,1,.828,8.4Z" fill="var(--primary_cotrast)"/>
        <path id="Pfad_53" data-name="Pfad 53" d="M5.315,2.4A1.258,1.258,0,0,1,6.7,1.181,1.253,1.253,0,0,1,8.062,2.4,1.243,1.243,0,0,1,6.7,3.6a1.247,1.247,0,0,1-1.38-1.2m.193,1.822H7.859v6.729H5.508Z" fill="var(--primary_cotrast)"/>
        <path id="Pfad_54" data-name="Pfad 54" d="M12.226,4.144a3.149,3.149,0,0,1,3.286,2.662H13.018a.837.837,0,0,0-.84-.66c-.576,0-.959.48-.959,1.44s.383,1.451.959,1.451a.825.825,0,0,0,.84-.66h2.494a3.17,3.17,0,0,1-3.286,2.663A3.223,3.223,0,0,1,8.82,7.586a3.22,3.22,0,0,1,3.406-3.442" fill="var(--primary_cotrast)"/>
        <path id="Pfad_55" data-name="Pfad 55" d="M16.487,2.081h2.351V6.866L20.7,4.227h2.818L20.865,7.61l2.7,3.346H20.7L18.838,8.2v2.758H16.487Z" fill="var(--primary_cotrast)"/>
        <path id="Pfad_56" data-name="Pfad 56" d="M50.845,7.442a1.115,1.115,0,1,0-2.23,0v3.61H46.263V7.442a1.115,1.115,0,1,0-2.23,0v3.61H41.682V4.323h2.351v.888a2.317,2.317,0,0,1,1.955-.947A2.466,2.466,0,0,1,48.231,5.5a2.7,2.7,0,0,1,2.242-1.235A2.585,2.585,0,0,1,53.2,7.13v3.922H50.845Z" fill="var(--primary_cotrast)"/>
        <path id="Pfad_57" data-name="Pfad 57" d="M57.521,11.136A3.238,3.238,0,0,1,54.1,7.682,3.222,3.222,0,0,1,57.521,4.24a3.185,3.185,0,0,1,3.394,3.37,4.249,4.249,0,0,1-.037.551H56.429c.048.78.468,1.092.995,1.092a.878.878,0,0,0,.84-.528h2.507a3.194,3.194,0,0,1-3.25,2.411m-1.08-4.114h2.075A.923.923,0,0,0,57.508,6.1a1.014,1.014,0,0,0-1.067.923" fill="var(--primary_cotrast)"/>
        <path id="Pfad_58" data-name="Pfad 58" d="M32.085,8.071l.749.1.42-1.565-1.435-.091Z" fill="var(--companyColor)"/>
        <path id="Pfad_59" data-name="Pfad 59" d="M36.041,0H28.786A3.406,3.406,0,0,0,25.38,3.406v7.255a3.405,3.405,0,0,0,3.406,3.4h7.255a3.405,3.405,0,0,0,3.4-3.4V3.406A3.406,3.406,0,0,0,36.041,0M36.4,10.442l-3.562-.149V9.277L31.09,9.238l-.467-3.826,2.591.141.009-.626,1.218-.141-.39,4.408,1.265-.228-.005-6.291L29.405,4.938l.183,5.589,1.687.018v1.166l-3.218.543V3.336l8.98-1.873Z" fill="var(--companyColor)"/>
      </g>
    </g>
  </svg>
  
  )
}
