import { Button, Input, Select, Skeleton } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import QR_CTA from './QR_CTA'
import './DeviceData.css'
import ErrorContainer from './ErrorContainer'
import TicketContext from '../contexts/TicketContext'
import RoomIcon from '../graphics/RoomIcon'
import CloseIcon from '../graphics/CloseIcon'

function DeviceData() {
    const { roomID, activeDevice, changeActiveDevice , devices, roomData, changeRoomID, changeMode, changeRoomData } = useContext(TicketContext)
    const {Option} = Select
    
    //TODO Add functionality to create Ticket for broken QR-Code

    if (roomID === "") {
        return (
            <QR_CTA />
        )
    } else if (roomID !== "" && roomID !== "error") {
        if (roomData !== null) {
            return (
                <div className='DeviceData'>
                    <div className='DeviceData_row'>
                        <div className='DeviceShower'>
                            <div className='RoomContainer'>
                                <div className='RoomShower'>
                                <div className='RoomIcon_Container'>
                                    <RoomIcon size={28} />
                                </div>
                                <div className='RoomShower_right'>
                                    <span className='label label-room'>Raum:</span>
                                    <span className='Room'>
                                        {roomData.identifier}
                                    </span>
                                </div>
                                </div>
                                <Button onClick={() => {changeRoomID(""); changeMode(null); changeRoomData(null); changeActiveDevice(null)}} className='IconButton' icon={<CloseIcon size={24} />}/>
                            </div>
                            {/* DeviceIcon of activeDevice -> activeDevice will have property of type to decide which icon to display */}
                            <span className='label'>Gerät:</span>
                            <Select
                            autoFocus={activeDevice ? false : true}
                        
                            bordered={false}
                            value={activeDevice ? (devices.find(device => activeDevice == device._id) ? activeDevice : "") : ""}
                            className='Input-Select'
                            showSearch
                            placeholder="Gerät auswählen"
                            optionFilterProp='children'
                            onChange={(value) => changeActiveDevice(value)}
                            filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input.toLowerCase() >= 0)}
                            >
                                {
                                    devices.map(device => {
                                        return (
                                            <Option value={device._id} key={device._id}>{device.identifier}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                    
    
                </div>
            )
        } else if (!roomData) {
            return (
                <div className='Skeleton-RoomShower'>
                <Skeleton.Avatar className='Skeleton-RoomAvatar' active/>
                <Skeleton.Button active />
                </div>
            )
        }
    } else if (roomID === "error") {
        return (
            <ErrorContainer />
        )
    } else {
        return (
            <div>Fehler. Bitte lade die Seite neu.</div>
        )
    }
}

export default DeviceData
