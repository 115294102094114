const ticketReducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE_NAME':
            return {
                ...state,
                name: action.payload
            }
        case 'CHANGE_MAIL':
            return {
                ...state,
                mail: action.payload
            }
        case 'CHANGE_ERRORS':
            return {
                ...state,
                errors: action.payload
            }
        case 'CHANGE_DESCRIPTION':
            return {
                ...state,
                description: action.payload
            }
        case 'CHANGE_DATE':
            return {
                ...state,
                date: action.payload
            }
        case 'CHANGE_roomID':
            return {
                ...state,
                roomID: action.payload
            }
        case 'CHANGE_SHOWSCANNER':
            return {
                ...state,
                showScanner: action.payload
            }
        case 'CHANGE_DEVICES':
            return {
                ...state,
                devices: action.payload,
            }
        case 'CHANGE_ACTIVETICKETS':
            return {
                ...state,
                activeTickets: action.payload
            }
        case 'CHANGE_ROOMDATA':
            return {
                ...state,
                roomData: action.payload,
            }
        case 'CHANGE_ACTIVEDEVICE':
            return {
                ...state,
                activeDevice: action.payload,
            }
        case 'CHANGE_MODE':
            return {
                ...state,
                mode: action.payload,
            }
        case 'CHANGE_COMPANYDATA':
            return {
                ...state,
                companyData: action.payload,
            }
        case 'ADD_TICKET':
            return {
                ...state,
                myTickets: [...state.myTickets, action.payload]
            }
        case 'SET_COMPANY':
            return {
                ...state,
                company: action.payload
            }
        case 'SET_TICKETSOFUSER':
            return {
                ...state,
                ticketsOfUser: action.payload
            }
        case 'REMOVE_TICKETOFUSER':
            return {
                ...state,
                ticketsOfUser: state.ticketsOfUser.filter(ticket => ticket._id != action.payload)
            }
        default:
            return state
    }
}

export default ticketReducer