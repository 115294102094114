import React, { useContext, useEffect } from 'react'
import TicketContext from '../contexts/TicketContext'
import ActiveTicketItem from './ActiveTicketItem'
import './ActiveTickets.css'
import TicketTable from './TicketTable'

function ActiveTickets() {
    const { roomID, activeDevice, activeTickets } = useContext(TicketContext)



    if (roomID !== "" && roomID !== "error" && activeDevice !== null && activeTickets.length > 0) {
        return (
            <div className='ActiveTickets'>
                <h2 style={{paddingBottom: "10px"}} className='ActiveTickets-header'>Aktive Tickets für dieses Gerät:</h2>
                    <div style={{display: "flex", flexDirection: "column", gap: "5px", paddingBottom: "30px" }}>
                        {activeTickets.map(ticket => (
                            <ActiveTicketItem data={ticket}/>
                        ))}
                    </div>
                    
                
            </div>
        )
    } else if (roomID !== "" && roomID !== 'error' && activeDevice !== null  ) {
        return (
            <div style={{padding: "0 20px"}}>
                <h2 style={{paddingBottom: "10px"}} className='ActiveTickets-header'>Aktive Tickets für dieses Gerät:</h2>
                <p>Für dieses Gerät gibt es keine aktiven Tickets.</p>
            </div>
        )
    } else {
        return (
            null
        )
    }
}

export default ActiveTickets
